<template>
    <v-container>
        <v-row justify="space-between">
            <v-col cols="12" sm="12">
                <Title
                    class="tw-flex tw-items-center tw-justify-start tw-mx-auto md:tw-text-3xl tw-text-2xl"
                    :name="'Bestellung überprüfen und abschicken'"
                />
            </v-col>
        </v-row>
        <v-row justify="start">
            <v-col cols="12" sm="4">
                <div class="font-weight-medium tw-text-lg tw-pb-14">
                    Rechnungsadresse
                </div>
                <div class="tw-text-section tw-pb-7">
                    <div>Martina Muster</div>
                    <div>MusterStrasse 15</div>
                    <div>55896 Musterstadt</div>
                    <div>Deutschland</div>
                </div>
                <IconLink
                    class="tw-flex tw-justify-start tw-items-center"
                    :size="'15'"
                    right
                    :text="'Rechnungsadresse ändern'"
                    :color="themePrimary"
                />
            </v-col>
            <v-col cols="12" sm="4">
                <div class="font-weight-medium tw-text-lg">Lieferadresse</div>
                <div class="font-weight-medium tw-pb-7 tw-text-lg">
                    (wie Rechnungsadresse)
                </div>
                <div class="tw-text-section tw-pb-7">
                    <div>Martina Muster</div>
                    <div>MusterStrasse 15</div>
                    <div>55896 Musterstadt</div>
                    <div>Deutschland</div>
                </div>
                <IconLink
                    class="tw-flex tw-justify-start tw-items-center"
                    :size="'15'"
                    right
                    :text="'Rechnungsadresse ändern'"
                    :color="themePrimary"
                />
            </v-col>
            <v-col cols="12" sm="4">
                <div class="font-weight-medium tw-pb-2 tw-text-lg">
                    Versandart
                </div>
                <div class="tw-text-section tw-pb-2">
                    <div>DHL</div>
                </div>
                <IconLink
                    class="tw-flex tw-justify-start tw-items-center tw-pb-11"
                    :size="'15'"
                    right
                    :text="'Versandart ändern'"
                    :color="themePrimary"
                />
                <div class="font-weight-medium tw-pb-2 tw-text-lg">
                    Zahlungsart
                </div>
                <div class="tw-text-section tw-pb-2">
                    <div>Paypal</div>
                </div>
                <IconLink
                    class="tw-flex tw-justify-start tw-items-center"
                    :size="'15'"
                    right
                    :text="'Zahlungsart ändern'"
                    :color="themePrimary"
                />
            </v-col>
        </v-row>
        <v-row justify="start">
            <v-col cols="12" sm="12">
                <Title
                    class="tw-flex tw-items-center tw-justify-start tw-mx-auto tw-pb-7 md:tw-text-3xl tw-text-2xl"
                    :name="'Produktübersicht'"
                />
                <div>
                    <v-divider />
                    <ArticleOverview
                        v-for="(article, key) in articles"
                        :key="key"
                        :article="article"
                    />
                    <v-row justify="end">
                        <v-col cols="12" sm="6" offset-sm="6">
                            <div
                                class="tw-flex tw-justify-between tw-font-light tw-py-7"
                            >
                                <div class="tw-font-light">
                                    Verpackung & Versand
                                </div>
                                <div class="font-weight-medium">Kostenlos</div>
                            </div>
                            <v-divider />
                            <div
                                class="tw-flex tw-justify-between tw-font-light tw-py-7"
                            >
                                <div class="tw-font-light">
                                    <span class="font-weight-medium"
                                        >Gesamtsumme</span
                                    >
                                    Inkl. MwSt.
                                </div>
                                <div class="font-weight-medium">EUR 190,00</div>
                            </div>
                            <v-divider />
                            <div class="product-invoice-description tw-pt-7">
                                Lorem ipsum dolor sit amet,
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <a
                                            target="_blank"
                                            href="https://www.lipsum.com/"
                                            @click.stop
                                            v-on="on"
                                        >
                                            sed do eiusmod tempor
                                        </a>
                                    </template>
                                    https://www.lipsum.com/
                                </v-tooltip>
                                consectetur adipiscing elit, sed do eiusmod
                                tempor incididunt ut labore et

                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <a
                                            target="_blank"
                                            href="https://www.lipsum.com/"
                                            @click.stop
                                            v-on="on"
                                        >
                                            dolore magna
                                        </a>
                                    </template>
                                    https://www.lipsum.com/
                                </v-tooltip>

                                aliqua. Ut enim ad minim veniam, quis nostrud.
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Title from '@/components/shared/typography/Title';
import ArticleOverview from '@/components/content/items/article/ArticleOverview';
import IconLink from '@/components/shared/buttons/IconLink';
import { articles } from '@/services/data-mock/articles';
export default {
    name: 'DeliveryApproval',
    components: {
        Title,
        IconLink,
        ArticleOverview,
    },
    data() {
        return {
            themePrimary: this.$vuetify.theme.themes.light.primary.base,
            articles,
        };
    },
    props: {},
    updated: () => {},
    methods: {},
};
</script>
<style scoped>
.product-invoice-description {
    font-size: 15px;
    padding-bottom: 15px;
    font-weight: 300;
    line-height: 1.2;
    color: black;
}
a {
    text-decoration: underline;
}
.tw-text-section {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.3;
    color: black;
}
</style>
