<template>
    <div>
        <div class="tw-flex tw-justify-start tw-items-center tw-py-5">
            <v-radio v-model="deliveryOption" value="1"></v-radio>
            <img :src="option.picture" :alt="option.text" width="100" />
            <div class="tw-font-light">{{ option.text }}</div>
        </div>
        <v-divider />
    </div>
</template>

<script>
export default {
    name: 'DeliveryOption',
    data() {
        return {
            themePrimary: this.$vuetify.theme.themes.light.primary.base,
            deliveryOption: null,
        };
    },
    props: {
        option: Object,
    },
    updated: () => {},
    methods: {},
};
</script>
<style scoped></style>
