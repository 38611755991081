import TypePictureOne from '../../assets/images/delivery-types/one.png';
import TypePictureTwo from '../../assets/images/delivery-types/two.png';
import TypePictureThree from '../../assets/images/delivery-types/three.png';
import TypePictureFour from '../../assets/images/delivery-types/four.png';
import TypePictureFive from '../../assets/images/delivery-types/five.png';

import OptionPictureOne from '../../assets/images/delivery-options/dhl.png';
import OptionPictureTwo from '../../assets/images/delivery-options/dhl-express.png';
import OptionPictureThree from '../../assets/images/delivery-options/gls.png';
import OptionPictureFour from '../../assets/images/delivery-options/ups.png';
import OptionPictureFive from '../../assets/images/delivery-options/ups-express.png';
import OptionPictureSix from '../../assets/images/delivery-options/tnt.png';

import OptionStepOne from '../../assets/images/delivery-steps/d-step1.png';
import OptionStepTwo from '../../assets/images/delivery-steps/d-step2.png';
import OptionStepThree from '../../assets/images/delivery-steps/d-step3.png';
import OptionStepFour from '../../assets/images/delivery-steps/d-step4.png';

const deliveryTypes = [
    {
        picture: TypePictureOne,
        name: 'Wunchadresse',
    },
    {
        picture: TypePictureTwo,
        name: 'Filialabholung',
    },
    {
        picture: TypePictureThree,
        name: 'DHL Packstation',
    },
    {
        picture: TypePictureFour,
        name: 'DHL Filiale',
    },
    {
        picture: TypePictureFive,
        name: 'DPD Pickup Shop',
    },
];

const deliveryOptions = [
    {
        picture: OptionPictureOne,
        text: 'DHL (Kostenlos)',
    },
    {
        picture: OptionPictureTwo,
        text: 'DHL Express (+4,50€)',
    },
    {
        picture: OptionPictureThree,
        text: 'GLS (Kostenlos)',
    },
    {
        picture: OptionPictureFour,
        text: 'GLS (Kostenlos)',
    },
    {
        picture: OptionPictureFive,
        text: 'DHL Express (+4,50€)',
    },
    {
        picture: OptionPictureSix,
        text: 'TNT (Kostenlos)',
    },
];

const deliverySteps = [
    {
        picture: OptionStepOne,
        text: '1. Ihre Bestullung wird für den Versand vorbereitet',
    },
    {
        picture: OptionStepTwo,
        text:
            '2. Der Versanddienstleister holt die Waren in unserem Lager ab. Die Zustellung erfolgt Mo.-Fr.',
    },
    {
        picture: OptionStepThree,
        text:
            '3. Am Versandtag erhalten Sie eine E-Mail mit der Trackingnummer, um den Status Ihrer Sendung zu verfolgen.',
    },
    {
        picture: OptionStepFour,
        text: '4. Ihre Bestellung trifft bei Ihrer angegebenen Adresse ein.',
    },
];

export { deliveryOptions, deliveryTypes, deliverySteps };
