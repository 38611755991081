<template>
    <div class="tw-grid md:tw-grid-cols-2 tw-grid-cols-1">
        <div
            class="lg:pr-10 lg:tw-pr-10 tw-pr-3 xs:tw-pr-0 tw-order-last md:tw-order-first"
        >
            <Title
                class="tw-flex tw-items-center tw-justify-center tw-mx-auto tw-pt-10 tw-pb-12"
                :size="'30'"
                :name="'Neu hier?'"
            />
            <div
                v-for="(specification, key) in specifications"
                :key="key"
                class="tw-pb-3"
            >
                <v-icon class="px-5 s-icon" color="green darken-2">{{
                    specification.icon
                }}</v-icon>
                <span class="s-content">{{ specification.content }}</span>
            </div>
        </div>
        <div class="lg:tw-pl-10 lg:tw-pl-10 tw-pl-3 xs:tw-pl-0">
            <Title
                class="tw-flex tw-items-center tw-justify-center tw-mx-auto tw-pt-10 tw-pb-12"
                :size="'30'"
                :name="'Anmelden'"
            />
            <v-form ref="sign-in">
                <v-text-field
                    tile
                    label="E-Mail Adresse*"
                    outlined
                ></v-text-field>
                <v-text-field tile label="Passwort*" outlined></v-text-field>
                <div
                    class="tw-flex justify-end tw-items-center flat-icon-button"
                    :style="{ color: themePrimary }"
                >
                    Passwort vergessen?
                    <div class="pl-5">
                        <SvgArrowRight :color="themePrimary" />
                    </div>
                </div>
            </v-form>
        </div>
    </div>
</template>

<script>
import Title from '@/components/shared/typography/Title';
import SvgArrowRight from '@/components/shared/icons/SvgArrowRight';
import membershipSpecifications from '@/services/data-mock/membership';
export default {
    name: 'SignIn',
    components: {
        Title,
        SvgArrowRight,
    },
    data() {
        return {
            specifications: membershipSpecifications,
            themePrimary: this.$vuetify.theme.themes.light.primary.base,
        };
    },
    props: {},
    updated: () => {},
    methods: {},
};
</script>
<style scoped>
.s-icon {
    font-size: 40px;
    line-height: 0.5;
}
.s-content {
    font-size: 20px;
    font-weight: 300;
}
.flat-icon-button {
    font-size: 18px;
}

.v-input >>> fieldset {
    border-color: #9e9e9e;
    color: #858585;
}

.v-input >>> .v-input__slot {
    border-radius: 0;
}

.v-input >>> label {
    color: #858585;
    font-weight: 300;
    font-size: 20px;
}

@media (max-width: 639px) {
    .s-content {
        font-size: 15px;
        font-weight: 300;
    }
    .v-icon {
        font-size: 20px !important;
    }
}
</style>
