<template>
    <v-col cols="12" xs="12" sm="6" md="3">
        <div
            class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-py-5"
        >
            <div class="d-step-picture tw-pb-7">
                <img :src="step.picture" :alt="step.text" class="step-picture" />
            </div>
            <div class="tw-font-light tw-text-center d-step-text">
                {{ step.text }}
            </div>
        </div>
    </v-col>
</template>

<script>
export default {
    name: 'DeliveryOption',
    data() {
        return {
            themePrimary: this.$vuetify.theme.themes.light.primary.base,
            deliveryOption: null,
        };
    },
    props: {
        step: Object,
    },
    updated: () => {},
    methods: {},
};
</script>
<style scoped>
.d-step-text {
    font-size: 15px;
}
.step-picture {
    width: 150px;
    height: 100px;
}
</style>
