<template>
    <v-container>
        <v-item-group mandatory>
            <v-row>
                <v-col
                    v-for="(option, key) in options"
                    :key="key"
                    cols="12"
                    class="pa-2"
                    :md="md"
                    :lg="lg"
                    :sm="sm"
                >
                    <v-item v-slot="{ active, toggle }">
                        <slot
                            :option="option"
                            :active="active"
                            :toggle="toggle"
                        />
                    </v-item>
                </v-col>
            </v-row>
        </v-item-group>
    </v-container>
</template>
<script>
export default {
    name: 'ItemGroup',
    props: {
        options: {
            type: Array,
        },
        md: [String, Number],
        sm: [String, Number],
        lg: [String, Number],
    },
};
</script>
<style scoped>

</style>
