<template>
    <v-container>
        <v-form ref="delivery">
            <v-row justify="space-between">
                <v-col cols="12" sm="12" class="tw-pt-0">
                    <Title
                        class="tw-flex tw-items-center tw-justify-start tw-mx-auto tw-pb-7 md:tw-text-3xl tw-text-2xl"
                        :name="'Lieferoptionen'"
                    />
                    <ItemGroup :options="deliveryTypes" :sm="6" :md="4">
                        <template slot-scope="{ option, active, toggle }">
                            <v-card
                                :style="{
                                    borderColor: active ? '#388E3C' : '',
                                    borderWidth: active ? '2px' : '',
                                }"
                                class="tw-flex tw-flex-col tw-justify-center tw-items-center card-check"
                                height="150"
                                flat
                                @click="toggle"
                            >
                                <img
                                    width="70"
                                    class="tw-pb-1"
                                    :src="option.picture"
                                    :alt="option.name"
                                />
                                <div class="tw-font-light option-name">
                                    {{ option.name }}
                                </div>
                            </v-card>
                        </template>
                    </ItemGroup>
                    <div class="pa-2">
                        <v-text-field
                            label="Zusatzinformationen"
                            outlined
                        ></v-text-field>
                        <v-text-field label="Vorname*" outlined></v-text-field>
                        <v-text-field label="Nachname*" outlined></v-text-field>
                        <v-text-field label="Straße*" outlined></v-text-field>
                        <v-text-field
                            label="Hausnummer*"
                            outlined
                        ></v-text-field>
                        <v-text-field label="PLZ*" outlined></v-text-field>
                        <v-text-field label="Stadt*" outlined></v-text-field>
                        <v-select
                            v-model="country"
                            :items="countries"
                            label="Deutschland"
                            :hint="`${country.state}, ${country.abbr}`"
                            item-text="state"
                            item-value="abbr"
                            outlined
                        ></v-select>
                        <v-text-field
                            label="Telefonnummer*"
                            outlined
                        ></v-text-field>
                    </div>
                </v-col>
            </v-row>
            <v-row justify="space-between">
                <v-col cols="12" sm="12">
                    <div class="tw-text-section">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex.
                    </div>
                </v-col>
            </v-row>
            <v-row justify="space-between">
                <v-col cols="12" sm="12">
                    <Title
                        class="tw-flex tw-items-center tw-justify-start tw-mx-auto tw-pb-2 md:tw-text-3xl tw-text-2xl"
                        :name="'Rechnungsadresse'"
                    />
                    <v-radio-group v-model="row" row>
                        <v-radio
                            label="Gleich wie Lieferadresse"
                            value="address-one"
                        ></v-radio>
                        <v-radio
                            label="Abweichende Rechnungadresse"
                            value="address-two"
                        ></v-radio>
                    </v-radio-group>
                    <v-textarea
                        outlined
                        name="note"
                        label="Ihre Mitteilung an uns"
                    ></v-textarea>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import Title from '@/components/shared/typography/Title';
import ItemGroup from '@/components/shared/item-group/ItemGroup';
import { deliveryTypes } from '@/services/data-mock/delivery';
export default {
    name: 'SignUp',
    components: {
        Title,
        ItemGroup,
    },
    data() {
        return {
            themePrimary: this.$vuetify.theme.themes.light.primary.base,
            terms: false,
            country: { state: 'Deutschland', abbr: 'DE' },
            countries: [
                { state: 'Deutschland', abbr: 'DE' },
                { state: 'United Kingdom', abbr: 'UK' },
                { state: 'France', abbr: 'FR' },
                { state: 'Italy', abbr: 'IT' },
                { state: 'Spain', abbr: 'SP' },
            ],
            deliveryTypes,
            rules: {
                required: value => !!value || 'Erforderlich.',
                min: v => v.length >= 8 || 'Mindestens 8 Zeichen',
            },
            row: null,
        };
    },
    props: {},
    updated: () => {},
    methods: {},
};
</script>
<style scoped>
.v-input >>> fieldset {
    border-color: #9e9e9e;
    color: #858585;
}

.v-input >>> .v-input__slot {
    border-radius: 0;
}

.v-input >>> label {
    color: #858585;
    font-weight: 300;
    font-size: 20px;
}

.v-radio >>> .v-label {
    font-size: 16px;
}

.tw-text-section {
    font-size: 18px;
    padding-bottom: 15px;
    font-weight: 300;
    line-height: 1.3;
    color: black;
}

.card-check {
    border: 1px solid #9e9e9e;
    border-radius: 0;
}

@media (max-width: 1300px) {
    .option-name {
       font-size: 12px;
       padding: 5px 5px;
    }
}
</style>
