import facebook from '../../assets/images/socials/icon-fb.svg';
import instagram from '../../assets/images/socials/icon-instagram-farbig.svg';
import pinterest from '../../assets/images/socials/icon-pinterest.svg';
import twitter from '../../assets/images/socials/icon-twitter.svg';

const socialLinks = [
    {
        icon: facebook,
        link: 'https://www.facebook.com/',
    },
    {
        icon: instagram,
        link: 'https://www.instagram.com/',
    },
    {
        icon: pinterest,
        link: 'https://www.pinterest.com/',
    },
    {
        icon: twitter,
        link: 'https://twitter.com/',
    },
];

export default socialLinks;
