<template>
    <v-container>
        <v-form ref="delivery">
            <v-row justify="space-between">
                <v-col cols="12" sm="12" class="tw-flex tw-flex-col">
                    <Title
                        class="tw-flex tw-items-center tw-justify-start tw-pb-7"
                        :size="'25'"
                        :name="'Bevorzugten Versanddienstleister wählen'"
                    />
                    <div class="tw-text-section">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris.
                    </div>
                    <div v-for="(option, key) in deliveryOptions" :key="key">
                        <DeliveryOption :option="option" />
                    </div>
                    <div class="tw-text-section tw-pt-10">
                        Text tbd ich möchte 0,25€ hinzufügen, um den CO2-Abdruck
                        meiner Bestellung auszugleichen.
                    </div>
                    <div class="tw-text-section">
                        <v-tooltip bottom>
                            <template>
                                <a
                                    target="_blank"
                                    href="https://koffer.de/"
                                    @click.stop
                                >
                                    Was ist das?
                                </a>
                            </template>
                            https://koffer.de/
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import Title from '@/components/shared/typography/Title';
import DeliveryOption from './DeliveryOption';
import { deliveryOptions } from '@/services/data-mock/delivery';
export default {
    name: 'DeliveryOptionList',
    components: {
        Title,
        DeliveryOption,
    },
    data() {
        return {
            themePrimary: this.$vuetify.theme.themes.light.primary.base,
            deliveryOptions,
        };
    },
    props: {},
    updated: () => {},
    methods: {},
};
</script>
<style scoped>
.tw-text-section {
    font-size: 18px;
    padding-bottom: 15px;
    font-weight: 300;
    line-height: 1.3;
    color: black
}
</style>
