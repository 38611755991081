<template>
    <div>
        <validation-observer ref="observer" v-slot="{ invalid }">
            <v-row justify="space-between">
                <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    offset-md="3"
                    class="tw-pt-10"
                >
                    <Title
                        class="tw-flex tw-items-center tw-justify-start tw-mx-auto md:tw-pb-10 md:tw-text-3xl tw-text-xl"
                        :name="'Neues Kundenkonto erstellen'"
                    />
                    <v-form @submit.prevent="handleSignUp">
                        <v-radio-group v-model="gender" row>
                            <div class="pr-3 gender-label xs:tw-w-full xs:tw-pb-2">Anrede:</div>
                            <v-radio label="Frau" value="frau"></v-radio>
                            <v-radio label="Herr" value="herr"></v-radio>
                            <v-radio
                                label="Keine Angabe"
                                value="keine_angabe"
                            ></v-radio>
                        </v-radio-group>
                        <validation-provider
                            v-slot="{ errors }"
                            name="Name"
                            rules="required|max:10"
                        >
                            <v-text-field
                                v-model="firstName"
                                :error-messages="errors"
                                label="Name*"
                                required
                                outlined
                            ></v-text-field>
                        </validation-provider>
                        <validation-provider
                            v-slot="{ errors }"
                            name="Vorname"
                            rules="required|max:10"
                        >
                            <v-text-field
                                v-model="lastName"
                                :error-messages="errors"
                                label="Vorname*"
                                required
                                outlined
                            ></v-text-field>
                        </validation-provider>
                        <validation-provider
                            v-slot="{ errors }"
                            name="email"
                            rules="required|email"
                        >
                            <v-text-field
                                v-model="email"
                                :error-messages="errors"
                                label="E-mail Adresse*"
                                required
                                outlined
                            ></v-text-field>
                        </validation-provider>

                        <validation-provider
                            v-slot="{ errors }"
                            name="password"
                            rules="required|min:8"
                        >
                            <v-text-field
                                outlined
                                v-model="password"
                                :append-icon="
                                    showPassword ? 'mdi-eye' : 'mdi-eye-off'
                                "
                                :error-messages="errors"
                                :type="showPassword ? 'text' : 'password'"
                                name="password"
                                label="Passwort*"
                                hint="Mindestens 8 Zeichen"
                                class="input-group--focused"
                                @click:append="showPassword = !showPassword"
                            ></v-text-field>
                        </validation-provider>
                        <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                            name="checkbox"
                        >
                            <v-checkbox
                                v-model="terms"
                                type="checkbox"
                                :error-messages="errors"
                                required
                            >
                                <template v-slot:label>
                                    <div class="text-section">
                                        Ja, ich möchte per E-Mail Newsletter
                                        über Trends, Aktionen & Gutscheine
                                        informiert werden.
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <a
                                                    target="_blank"
                                                    href="https://vuejs.org/"
                                                    @click.stop
                                                    v-on="on"
                                                >
                                                    Abmeldung jederzeit möglich.
                                                </a>
                                            </template>
                                            Öffnet in neuem Fenster
                                        </v-tooltip>
                                    </div>
                                </template>
                            </v-checkbox>
                        </validation-provider>
                    </v-form>
                </v-col>
            </v-row>
            <v-row justify="space-between">
                <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    offset-md="3"
                    class="tw-pb-5 tw-px-2"
                >
                    <v-btn
                        depressed
                        block
                        color="primary"
                        class="next-step-btn tw-capitalize"
                        @click="handleSignUp"
                        :disabled="invalid"
                        height="55"
                        tile
                    >
                        Speichern
                    </v-btn>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" sm="12" class="tw-pt-5">
                    <IconLink
                        class="tw-flex tw-justify-center tw-items-center"
                        :size="'20'"
                        left
                        :text="'Zurück'"
                        :color="themePrimary"
                    />
                </v-col>
            </v-row>
            <v-row justify="space-between">
                <v-col cols="12" sm="12" md="6" offset-md="3">
                    <div class="text-section tw-font-light tw-pb-3">
                        Mit deiner Registrierung stimmst du unseren
                        <a href="#">AGB</a> und den
                        <a href="#">Datenschutzbestimmungen</a> zu.
                    </div>
                    <div class="text-section tw-font-light">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex.
                    </div>
                </v-col>
            </v-row>
        </validation-observer>
    </div>
</template>

<script>
import {
    required,
    digits,
    email,
    max,
    min,
    regex,
} from 'vee-validate/dist/rules';
import IconLink from '@/components/shared/buttons/IconLink';
import {
    extend,
    ValidationObserver,
    ValidationProvider,
    setInteractionMode,
} from 'vee-validate';
setInteractionMode('eager');
extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
});

extend('required', {
    ...required,
    message: '{_field_} can not be empty',
});

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
});

extend('min', {
    ...min,
    message: '{_field_} may not be less than {length} characters',
});

extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
});

extend('email', {
    ...email,
    message: 'Email must be valid',
});

import Title from '@/components/shared/typography/Title';
export default {
    name: 'SignUp',
    components: {
        Title,
        ValidationProvider,
        ValidationObserver,
        IconLink,
    },
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            gender: null,
            terms: null,
            themePrimary: this.$vuetify.theme.themes.light.primary.base,
            showPassword: false,
            password: 'Passwort',
        };
    },
    props: {},
    updated: () => {},
    methods: {
        handleSignUp() {
            this.$refs.observer.validate();
            this.$emit('next-step');
        },
    },
};
</script>
<style scoped>
.v-input >>> fieldset {
    border-color: #9e9e9e;
    color: #858585;
}

.v-input >>> .v-input__slot {
    border-radius: 0;
}

.v-input >>> label {
    color: #858585;
    font-weight: 300;
    font-size: 20px;
}

.text-section {
    font-size: 18px;
    line-height: 1.3;
    color: #858585;
}

.text-section a {
    text-decoration: underline;
    color: theme('colors.gray-disabled');
}

.gender-label {
    color: #858585;
    font-weight: 300;
    font-size: 20px;
}

.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: theme('colors.gray-disabled') !important;
    color: white !important;
}

@media (max-width: 767px) {
    .v-input--selection-controls >>> .v-label {
        font-size: 14px;
    }
}
</style>
