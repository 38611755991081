<template>
    <v-container>
        <v-form ref="delivery">
            <v-row justify="space-between">
                <v-col cols="12" sm="12" class="tw-flex tw-flex-col">
                    <Title
                        class="tw-flex tw-items-center tw-justify-center tw-mx-auto tw-pb-7"
                        :size="'30'"
                        :name="'Wie geht es jetzt weiter?'"
                    />

                    <v-row>
                        <DeliveryStep
                            v-for="(step, key) in deliverySteps"
                            :key="key"
                            :step="step"
                        />
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import Title from '@/components/shared/typography/Title';
import DeliveryStep from './DeliveryStep';
import { deliverySteps } from '@/services/data-mock/delivery';
export default {
    name: 'DeliveryOptionList',
    components: {
        Title,
        DeliveryStep,
    },
    data() {
        return {
            themePrimary: this.$vuetify.theme.themes.light.primary.base,
            deliverySteps,
        };
    },
    props: {},
    updated: () => {},
    methods: {},
};
</script>
<style scoped>

</style>
