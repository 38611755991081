<template>
    <div class="main-page-size checkout-container">
        <v-stepper alt-labels v-model="stepNumber" flat>
            <v-stepper-header>
                <v-stepper-step :complete="stepNumber > 1" step="1">
                    Anmelden
                </v-stepper-step>
                <v-divider />
                <v-stepper-step :complete="stepNumber > 2" step="2">
                    Adressen
                </v-stepper-step>
                <v-divider />
                <v-stepper-step :complete="stepNumber > 3" step="3">
                    Versand
                </v-stepper-step>
                <v-divider />
                <v-stepper-step :complete="stepNumber > 4" step="4">
                    Zahlungsart
                </v-stepper-step>
                <v-divider />
                <v-stepper-step :complete="stepNumber > 5" step="5">
                    Bestellung
                </v-stepper-step>
                <v-divider />
                <v-stepper-step step="6">
                    Bestätigen
                </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
                <v-stepper-content step="1">
                    <SignIn />
                    <div class="tw-grid md:tw-grid-cols-2 tw-grid-cols-1">
                        <div
                            class="lg:tw-pr-10 tw-py-6 md:tw-pr-3 tw-order-last md:tw-order-first"
                        >
                            <v-btn
                                depressed
                                block
                                color="primary"
                                class="tw-capitalize"
                                @click="(stepNumber = 2), scrollToTop()"
                                height="55"
                                tile
                            >
                                Jetzt registrieren
                            </v-btn>
                            <div class="registration-description tw-py-5">
                                Jetzt als Gast bestellen und nach der Bestellung
                                mit einem Klick ein Konto anlegen.
                            </div>
                            <div>
                                <v-btn
                                    class="tw-capitalize"
                                    outlined
                                    block
                                    color="primary"
                                    height="55"
                                    tile
                                >
                                    Als Gast fortfahren
                                </v-btn>
                            </div>
                        </div>
                        <div class="lg:tw-pl-10 tw-py-6 md:tw-pl-3">
                            <v-btn
                                class="tw-capitalize"
                                depressed
                                block
                                color="primary"
                                @click="(stepNumber = 1), scrollToTop()"
                                height="55"
                                tile
                            >
                                Anmelden
                            </v-btn>
                        </div>
                    </div>
                    <div class="tw-grid tw-grid-cols-1">
                        <div class="tw-pt-10">
                            <IconLink
                                class="tw-flex tw-justify-center tw-items-center"
                                :size="'20'"
                                left
                                :text="'Zurück zum WarenKorb'"
                                :color="themePrimary"
                            />
                        </div>
                    </div>
                </v-stepper-content>
                <v-stepper-content step="2">
                    <SignUp v-on:next-step="handleSignUpNext" />
                </v-stepper-content>
                <v-stepper-content step="3">
                    <v-row justify="space-between">
                        <v-col cols="12" sm="12" md="6">
                            <DeliveryForm />
                        </v-col>
                        <v-divider
                            vertical
                            class="vertical-devider"
                        ></v-divider>
                        <v-col cols="12" sm="12" md="5">
                            <DeliveryOverview />
                        </v-col>
                    </v-row>
                    <v-row justify="space-between">
                        <v-col cols="12" sm="12" md="6" class="tw-pb-5 tw-px-5">
                            <v-btn
                                class="tw-capitalize"
                                depressed
                                block
                                color="primary"
                                @click="(stepNumber = 4), scrollToTop()"
                                height="55"
                                tile
                            >
                                Speichern
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row justify="space-between">
                        <v-col cols="12" sm="12" md="6" class="tw-pt-10">
                            <IconLink
                                class="tw-flex tw-justify-center tw-items-center"
                                :size="'20'"
                                left
                                :text="'Zurück'"
                                :color="themePrimary"
                            />
                        </v-col>
                    </v-row>
                </v-stepper-content>
                <v-stepper-content step="4">
                    <v-row justify="space-between">
                        <v-col cols="12" sm="12" md="6">
                            <DeliveryOptionList />
                        </v-col>
                        <v-divider
                            vertical
                            class="vertical-devider"
                        ></v-divider>
                        <v-col cols="12" sm="12" md="5">
                            <DeliveryOverview />
                        </v-col>
                    </v-row>
                    <v-row justify="space-between">
                        <v-col cols="12" sm="12" md="6" class="tw-pb-5 tw-px-5">
                            <v-btn
                                class="tw-capitalize"
                                depressed
                                block
                                color="primary"
                                @click="(stepNumber = 5), scrollToTop()"
                                height="55"
                                tile
                            >
                                Speichern
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row justify="space-between">
                        <v-col cols="12" sm="12" md="6" class="tw-pt-10">
                            <IconLink
                                class="tw-flex tw-justify-center tw-items-center"
                                :size="'20'"
                                left
                                :text="'Zurück'"
                                :color="themePrimary"
                            />
                        </v-col>
                    </v-row>
                </v-stepper-content>
                <v-stepper-content step="5">
                    <v-col cols="12" sm="12">
                        <DeliveryApproval />
                    </v-col>
                    <v-row justify="space-between">
                        <v-col
                            cols="12"
                            sm="6"
                            offset-sm="6"
                            class="tw-pb-5 tw-px-2"
                        >
                            <v-btn
                                class="tw-capitalize"
                                depressed
                                block
                                color="primary"
                                @click="(stepNumber = 6), scrollToTop()"
                                height="55"
                                tile
                            >
                                Jetzt Kaufen
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>
                <v-stepper-content step="6">
                    <v-row justify="space-between">
                        <v-col cols="12" sm="12" md="6" offset-md="3">
                            <Thank />
                        </v-col>
                    </v-row>
                    <v-divider />
                    <v-col cols="12" sm="12">
                        <DeliveryStepList />
                    </v-col>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </div>
</template>
<script>
import SignIn from './authentication/SignIn';
import SignUp from './authentication/SignUp';
import DeliveryForm from './delivery/DeliveryForm';
import DeliveryOverview from './delivery/DeliveryOverview';
import DeliveryOptionList from './delivery/DeliveryOptionList';
import DeliveryApproval from './delivery/DeliveryApproval';
import DeliveryStepList from './delivery/delivery-steps/DeliveryStepList';
import Thank from './thank/Thank';
import IconLink from '@/components/shared/buttons/IconLink';
export default {
    components: {
        SignIn,
        SignUp,
        Thank,
        IconLink,
        DeliveryForm,
        DeliveryOverview,
        DeliveryOptionList,
        DeliveryStepList,
        DeliveryApproval,
    },
    data() {
        return {
            stepNumber: 1,
            themePrimary: this.$vuetify.theme.themes.light.primary.base,
        };
    },
    methods: {
        handleSignUpNext() {
            this.stepNumber = 3;
            this.scrollToTop();
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
    },
};
</script>
<style scoped>
.v-stepper {
    margin-top: 1%;
}

.v-stepper__header {
    padding: 0 17%;
    box-shadow: none;
}
.v-stepper__header >>> .v-stepper__step__step {
    font-size: 20px;
    font-weight: 900;
    width: 40px;
    height: 40px;
}

.v-stepper__step.v-stepper__step--inactive >>> .v-stepper__step__step {
    background-color: theme('colors.gray-disabled-light') !important;
}

.v-stepper__step >>> .v-stepper__label {
    color: black;
    font-weight: 300;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
    margin: 43px -56px 0;
    border-color: #000;
}
.registration-description {
    font-size: 20px;
    font-weight: 300;
}
.text-section {
    font-size: 18px;
    padding-bottom: 15px;
    font-weight: 300;
    line-height: 1.3;
    color: black;
}
.vertical-devider {
    height: 590px;
}
.v-stepper >>> .v-stepper__wrapper {
    padding: 0 15px;
}
@media (max-width: 1350px) {
    .v-stepper__header >>> .v-stepper__step__step {
        font-size: 13px;
        font-weight: 900;
        width: 25px;
        height: 25px;
    }
    .v-stepper__header >>> .v-stepper__label {
        font-size: 12px;
    }
    .v-stepper--alt-labels >>> .v-divider {
        margin: 37px -60px 0 !important;
    }
}

@media (max-width: 1050px) {
    .v-stepper__header >>> .v-stepper__label {
        display: none;
    }
    .v-stepper__header >>> .v-stepper__step__step {
        font-size: 15px;
        font-weight: 900;
        width: 30px;
        height: 30px;
    }
    .v-stepper--alt-labels >>> .v-divider {
        margin: 38px -65px 0 !important;
    }
    .v-stepper >>> .v-stepper__content,
    .v-stepper >>> .v-stepper__content .v-stepper__wrapper {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 450px) {
    .v-stepper__header >>> .v-stepper__label {
        display: none;
    }
    .v-stepper__header >>> .v-stepper__step__step {
        font-size: 13px;
        font-weight: 600;
        width: 25px;
        height: 25px;
    }
    .v-stepper--alt-labels >>> .v-divider {
        margin: 35px -38px 0 !important;
    }
    .v-stepper--alt-labels .v-stepper__step {
        flex-basis: 110px;
    }
}

@media (max-width: 767px) {
    .v-stepper__header {
        padding: 0;
    }
}

@media (max-width: 639px) {
    .registration-description {
        font-size: 15px;
    }
}
</style>
