const membershipSpecifications = [
    {
        icon: 'mdi-check',
        content:
            'Bestelle beim nächsten Mal noch schneller - auf all deinen Geräten.',
    },
    {
        icon: 'mdi-check',
        content: 'Jederzeit wissen, wo sich deine Bestellung befindet.',
    },
    {
        icon: 'mdi-check',
        content: 'Stelle deine persönliche Wunschliste zusammen.',
    },
];

export default membershipSpecifications;
