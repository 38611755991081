<template>
    <v-container>
        <v-row justify="space-between">
            <v-col
                cols="12"
                sm="12"
                class="tw-flex tw-justify-center tw-items-center tw-flex-col"
            >
                <Title
                    class="tw-flex tw-items-center tw-justify-center tw-mx-auto tw-pb-7"
                    :size="'40'"
                    :name="'Vielen Dank!'"
                />
                <div
                    class="tw-text-section tw-text-center tw-font-light tw-pb-5"
                >
                    Ihre Bestellung ist erforlgreich bei uns eingegangen.
                </div>
                <div
                    class="tw-text-section tw-text-center font-weight-medium tw-pb-5"
                >
                    Ihre Bestellnummer lautet: 12345678.
                </div>
                <div
                    class="tw-text-section tw-text-center tw-font-light tw-pb-5"
                >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                </div>
                <div class="tw-text-section font-weight-medium tw-pb-5">
                    Sie möchten das nächste Mal noch schneller bestellen?
                </div>
                <div
                    class="tw-text-section tw-text-center tw-font-light tw-pb-5"
                >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam.
                </div>
            </v-col>
            <v-col cols="12" class="tw-flex tw-justify-center">
                <div class="md:tw-w-2/4 lg:tw-w-full">
                    <v-btn depressed block color="primary" height="55" tile>
                        Gastzugang umwandeln
                    </v-btn>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="12"
                class="tw-flex tw-justify-center tw-items-center tw-flex-col"
            >
                <div class="tw-pb-5 font-weight-medium">Koffer.de folgen:</div>
                <div class="tw-flex">
                    <a
                        v-for="(social, key) in socialLinks"
                        :key="key"
                        cols="12"
                        class="pa-2"
                        md="4"
                        :href="social.link"
                    >
                        <img
                            width="35"
                            class="pr-1"
                            :src="social.icon"
                            :alt="'social'"
                        />
                    </a>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Title from '@/components/shared/typography/Title';
import socialLinks from '@/services/data-mock/socialLinks';
export default {
    name: 'Thank',
    components: {
        Title,
    },
    data() {
        return {
            socialLinks,
        };
    },
    props: {},
    updated: () => {},
    methods: {},
};
</script>
<style scoped>
.tw-text-section {
    color: black;
}
</style>
